import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/onborda/dist/Onborda.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/onborda/dist/OnbordaContext.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/metrics/MicrosoftClarity.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/prosemirror.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Crisp.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CustomCard/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/BackgroundContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/DocumentContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/EssaysContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/ExtracurricularRecommendationContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/IdeaContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/context/UserContext.tsx")